<template>
  <div class="account">
    <div class="form-container">
      <b-form-group label="E-Mail">
        <b-form-input
          type="text"
          v-model="userFormData.username"
        ></b-form-input>
      </b-form-group>
      <b-form-group v-if="user.role === 'admin'" label="Level">
        <b-form-select
          :options="roleOptions"
          v-model="userFormData.role"
        ></b-form-select>
      </b-form-group>
      <b-form-group label="Neues Passwort">
        <b-form-input
          v-model="userFormData.passwordFirst"
          type="password"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Passwort bestätigen">
        <b-form-input
          v-model="userFormData.passwordSecond"
          type="password"
        ></b-form-input>
      </b-form-group>
      <b-button class="float-right" @click="updateUser">Speichern</b-button>
    </div>
  </div>
</template>

<script>
import AZ from "@/utils/AzApi";

export default {
  name: "UserForm",
  components: {},
  props: {
    userData: { type: Object }
  },
  data() {
    return {
      user: AZ.user(),
      actionResult: {
        error: false,
        message: ""
      },
      userFormData: {},
      roleOptions: [
        { value: "admin", text: "admin" },
        { value: "user", text: "user" },
        { value: "extern", text: "extern" }
      ]
    };
  },

  computed: {
    alertType() {
      return this.actionResult.error ? "danger" : "primary";
    },
    toastHeader() {
      return this.actionResult.error
        ? "Profil nicht gespeichert"
        : "Profil erfolgreich gespeichert";
    }
  },

  mounted() {
    if (this.userData) {
      this.userFormData = this.userData;
    } else {
      if (this.user) {
        this.userFormData.username = this.user.username;
        this.userFormData.id = this.user.id;
      }
    }
  },

  methods: {
    makeToast() {
      this.$bvToast.toast(this.actionResult.message, {
        title: this.toastHeader,
        variant: this.alertType,
        solid: true
      });
    },

    updateUser() {
      if (
        this.userFormData.passwordFirst === this.userFormData.passwordSecond
      ) {
        const user = {
          id: this.userFormData.id
        };
        if (
          this.userFormData.username &&
          this.userFormData.username.length > 3
        ) {
          user.username = this.userFormData.username;
        }
        if (
          this.userFormData.passwordFirst &&
          this.userFormData.passwordFirst.length > 4
        ) {
          user.password = this.userFormData.passwordFirst;
        }
        AZ.persistUser(user).then(() => {
          this.actionResult = {
            error: false,
            message:
              "Profil am " +
              new Date().toLocaleString(undefined, {
                month: "2-digit",
                day: "2-digit",
                year: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false
              }) +
              " gespeichert."
          };
          this.makeToast();
          this.$emit("update");
        });
      } else {
        this.actionResult = {
          error: true,
          message:
            "Es gab einen Fehler bei der Bearbeitung: Die eingegebenen Passwörter stimmen nicht überein. "
        };
        this.makeToast();
      }
    }
  }
};
</script>

<style scoped>
button {
  margin: 1.4rem 0;
}

.card-body {
  padding: 0;
}

.form-container {
  padding: 1.4rem;
}

.card-header {
  font-size: 1.2rem;
  background-color: var(--primary);
  color: white;
}

.card {
  margin: 2rem 0;
}
</style>
