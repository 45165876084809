<template>
  <div class="base-confirmation-modal">
    <b-modal :title="title" id="confirm-modal" centered>
      {{ content }}
      <template v-slot:modal-footer>
        <b-button @click="doAction()" variant="primary">Ja</b-button>
        <b-button @click="$emit('hide')">Nein</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "BaseConfirmationModal",
  components: {},

  props: {
    title: { type: String, default: "Eintrag löschen" },
    content: {
      type: String,
      default: "Möchten Sie den Eintrag wirklich löschen?"
    }
  },
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    doAction() {
      this.$emit("confirm");
    }
  }
};
</script>

<style scoped>
button {
  width: 64px;
}
</style>
