<template>
  <div class="base-date-picker">
    <b-form-group
      :label="label"
      label-class="form-label"
      label-for="datepicker"
    >
      <b-form-datepicker
        v-model="date"
        locale="de"
        start-weekday="1"
        v-bind="labels.de || {}"
        @input="$emit('change', date)"
      ></b-form-datepicker>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "BaseDatePicker",
  components: {},
  props: {
    label: { type: String, default: "Datum" },
    value: { type: String, default: "" },
  },
  data() {
    return {
      labels: {
        de: {
          labelPrevDecade: "Vorheriges Jahrzehnt",
          labelPrevYear: "Vorheriges Jahr",
          labelPrevMonth: "Vorheriger Monat",
          labelCurrentMonth: "Aktueller Monat",
          labelNextMonth: "Nächster Monat",
          labelNextYear: "Nächstes Jahr",
          labelNextDecade: "Nächstes Jahrzehnt",
          labelToday: "Heute",
          labelSelected: "Ausgewähltes Datum",
          labelNoDateSelected: "Kein Datum gewählt",
          labelCalendar: "Kalender",
          labelNav: "Kalendernavigation",
          labelHelp: "Mit den Pfeiltasten durch den Kalender navigieren",
        },
      },
      date: this.value,
    };
  },

  watch: {
    value: function(newVal) {
      this.date = newVal;
    },
  },
};
</script>

<style scoped></style>
