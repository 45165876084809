<template>
  <div class="activities-table">
    <BaseTable :items="items" :header="headerItems">
      <template v-slot:cell(link)="data">
        <img
          src="edit-icon-dark.svg"
          alt="edit"
          class="mt-1 mx-3"
          @click="$emit('edit', data.item)"
        />
        <img
          src="delete-icon-dark.svg"
          alt="delete"
          v-b-modal.confirm-modal
          @click="activityToDelete = data.item.id"
        />
      </template>
      <template v-slot:cell(state)="data">
        <img
          v-if="data.item.running"
          src="/stop-icon.svg"
          alt="stop"
          @click="$emit('stop', data.item)"
        />
        <img
          v-else
          src="refresh-icon-dark.svg"
          alt="restart"
          @click="$emit('restart', data.item)"
        />
      </template>

      <template v-if="isMobile" v-slot:row-details="row">
        <b-card @click="toggleDescription(row.item.description)">
          <b-row class="mb-2">
            <b-col>{{ row.item.title }}</b-col>
          </b-row>
          <b-row class="mb-2" v-if="description === row.item.description">
            <hr />
            <b-col class="font-italic">{{ row.item.description }}</b-col>
          </b-row>
        </b-card>
      </template>
    </BaseTable>
    <BaseConfirmationModal
      title="Zeit löschen"
      @confirm="deleteActivity()"
      @hide="closeModal()"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import BaseTable from "@/components/BaseTable.vue";
import BaseConfirmationModal from "@/components/BaseConfirmationModal.vue";
import AZ from "@/utils/AzApi";

export default {
  name: "ActivitiesTable",
  components: {
    BaseTable,
    BaseConfirmationModal,
  },
  props: {
    entries: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      activityToDelete: 0,
      description: undefined,
      header: [
        {
          key: "state",
          label: "",
          sortable: false,
        },
        {
          key: "date",
          label: this.$t("activities.date"),
          sortable: true,
        },
        {
          key: "duration",
          label: this.$t("activities.duration"),
          sortable: true,
        },
        {
          key: "title",
          label: "Ticket",
          sortable: true,
        },
        {
          key: "start",
          label: this.$t("activities.startTime"),
          sortable: false,
        },
        {
          key: "end",
          label: this.$t("activities.endTime"),
          sortable: false,
        },
        {
          key: "description",
          label: this.$t("activities.description"),
          sortable: true,
        },

        { key: "link", label: this.$t("activities.edit"), sortable: false },
      ],
      headerMobile: [
        {
          key: "state",
          label: "",
          sortable: false,
        },
        {
          key: "date",
          label: this.$t("activities.date"),
          sortable: true,
        },
        {
          key: "duration",
          label: this.$t("activities.duration"),
          sortable: true,
        },
        { key: "link", label: this.$t("activities.edit"), sortable: false },
      ],
    };
  },
  computed: {
    headerItems() {
      return window.innerWidth < 768 ? this.headerMobile : this.header;
    },
    isMobile() {
      return window.innerWidth < 768;
    },
    items() {
      return this.entries;
    },
    alertType() {
      return this.actionResult.error ? "danger" : "primary";
    },
    toastHeader() {
      return this.actionResult.error
        ? this.$t("toast.editError")
        : this.$t("toast.editSuccess");
    },
  },
  methods: {
    toggleDescription(description) {
      if (this.description === undefined || this.description !== description)
        this.description = description;
      else this.description = undefined;
    },
    makeToast() {
      this.$bvToast.toast(this.actionResult.message, {
        title: this.toastHeader,
        variant: this.alertType,
        solid: true,
      });
    },
    closeModal() {
      this.$bvModal.hide("confirm-modal");
    },

    calculateMinutes(time) {
      const timeArr = time.split(":");
      return parseFloat(timeArr[0]) * 60 + parseFloat(timeArr[1]);
    },

    calculateDuration(start, end) {
      const startInMinutes = this.calculateMinutes(start);
      const endInMinutes = this.calculateMinutes(end);
      const durationInMinutes = endInMinutes - startInMinutes;
      const minutes = durationInMinutes % 60;
      const hours = (durationInMinutes - minutes) / 60;
      const minutesFormatted = minutes < 10 ? `0${minutes}` : `${minutes}`;
      const hoursFormatted = hours < 10 ? `0${hours}` : `${hours}`;
      return `${hoursFormatted}:${minutesFormatted}`;
    },

    deleteActivity() {
      AZ.deleteActivity(this.activityToDelete)
        .then(() => {
          const indexToDelete = this.items.findIndex(
            (item) => item.id === this.activityToDelete
          );
          this.items.splice(indexToDelete, 1);
          this.closeModal();
          this.actionResult = {
            error: false,
            message:
              "Zeit am " +
              new Date().toLocaleString(undefined, {
                month: "2-digit",
                day: "2-digit",
                year: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
              }) +
              " gelöscht.",
          };
          this.makeToast();
        })
        .catch((error) => {
          this.actionResult = {
            error: true,
            message:
              "Es gab einen Fehler bei der Bearbeitung: " + error.message,
          };
          this.makeToast();
        });
    },
  },
};
</script>

<style scoped>
h3 {
  font-size: 1.2rem;
  margin: 1rem 0;
}

.table-container {
  padding: 1.2rem;
}
</style>
