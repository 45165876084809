<template>
  <div class="time-tracker-form">
    <b-card bg-variant="light">
      <b-card-header
        header-tag="header"
        role="tab"
        :header-bg-variant="cardHeaderBgColor"
      >
        <div block v-b-toggle.form variant="info">{{ cardTitle }}</div>
      </b-card-header>
      <b-collapse id="form" visible accordion="form" role="tabpanel">
        <b-card-body>
          <div class="form-container">
            <div class="layout-container">
              <div class="input-wrapper">
                <b-form-group
                  :label="$t('activities.ticket')"
                  label-class="form-label"
                  label-for="ticket-select"
                >
                  <v-select
                    @search:focus="getSuggestionOptions()"
                    taggable
                    selectOnTab
                    id="ticket-select"
                    v-model="formData.issue"
                    :options="suggestionOptions"
                    label="title"
                  ></v-select>
                </b-form-group>
                <b-form-group
                  :label="$t('activities.description')"
                  label-class="form-label"
                  label-for="description-input"
                >
                  <b-form-textarea
                    id="description-input"
                    v-model="formData.description"
                  ></b-form-textarea>
                </b-form-group>
              </div>

              <div>
                <BaseDatePicker
                  @change="formData.date = $event"
                  :value="formData.date"
                />
                <div>
                  <!-- <div class="d-flex"> -->
                  <BaseTimePicker
                    :label="$t('activities.startTime')"
                    :value="formData.startTime"
                    @change="formData.startTime = $event"
                    @refresh="resetStartTime()"
                  />
                  <BaseTimePicker
                    :label="$t('activities.endTime')"
                    :value="formData.endTime"
                    @change="setDuration($event)"
                  />
                  <!-- </div> -->
                  <b-form-group
                    :label="$t('activities.duration')"
                    label-class="form-label"
                    label-for="duration-input"
                  >
                    <b-input-group append="min" id="duration-input">
                      <b-form-input
                        :value="formData.duration"
                        @input="setEndTimeOffsetInMinutes($event)"
                      />
                    </b-input-group>
                  </b-form-group>
                </div>
              </div>
            </div>
            <b-button class="" variant="primary" @click="persistActivity">{{
              buttonText
            }}</b-button>
            <b-button
              class=""
              variant="outline-secondary"
              @click="$emit('reset')"
              >{{ $t("activities.reset") }}</b-button
            >
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import BaseTimePicker from "@/components/BaseTimePicker.vue";
import BaseDatePicker from "@/components/BaseDatePicker.vue";
import { v4 as uuidv4 } from "uuid";
import AZ from "@/utils/AzApi";

export default {
  name: "TimeTrackerForm",
  components: {
    BaseTimePicker,
    BaseDatePicker,
  },
  props: {
    input: {
      type: Object,
      default: () => {
        return {
          id: "",
          issue: {
            title: "",
            source: "",
            externalId: "",
          },
          description: "",
          startTime: "",
          endTime: "",
          date: "",
        };
      },
    },
  },
  data() {
    return {
      formData: { id: "" },
      mode: "stopwatch",
      suggestionOptions: [],
      actionResult: {
        error: false,
        message: "",
      },
    };
  },
  computed: {
    cardTitle() {
      return this.formData.id !== ""
        ? this.$t("activities.update", { date: this.formData.date })
        : this.$t("activities.book");
    },
    buttonText() {
      const isRunning = !this.formData.endTime || this.formData.endTime === "";
      if (this.formData.id === "") {
        return isRunning
          ? this.$t("activities.startTimer")
          : this.$t("activities.submit");
      } else {
        return isRunning
          ? this.$t("activities.stop")
          : this.$t("activities.save");
      }
    },
    cardHeaderBgColor() {
      return this.formData.id !== "" ? "secondary" : "primary";
    },
    alertType() {
      return this.actionResult.error ? "danger" : "primary";
    },
    toastHeader() {
      return this.actionResult.error
        ? this.$t("toast.saveError")
        : this.$t("toast.saveSuccess");
    },
  },
  mounted() {
    this.formData = JSON.parse(JSON.stringify(this.input));
    this.getSuggestionOptions();
  },
  watch: {
    input: function(newVal) {
      this.formData = newVal;
    },
  },
  methods: {
    makeToast() {
      this.$bvToast.toast(this.actionResult.message, {
        title: this.toastHeader,
        variant: this.alertType,
        solid: true,
      });
    },
    getSuggestionOptions() {
      AZ.getSuggestions().then((response) => {
        this.suggestionOptions = response.data;
      });
    },
    resetStartTime() {
      const now = new Date();
      this.formData.startTime = now.toTimeString().slice(0, 5);
      this.formData.date = now.toISOString().slice(0, 10);
      this.$emit("refresh");
    },

    setEndTimeOffsetInMinutes(minutes) {
      const startTime =
        this.formData.date +
        "T" +
        this.getTimeWithSeconds(this.formData.startTime);

      const start = Date.parse(startTime);
      const endTime = new Date(start + minutes * 60000);
      this.formData.endTime = endTime.toTimeString().slice(0, 5);
    },

    setDuration(endTime) {
      this.formData.endTime = endTime;
      const startDateTime =
        this.formData.date +
        "T" +
        this.getTimeWithSeconds(this.formData.startTime);
      const endDateTime =
        this.formData.date + "T" + this.getTimeWithSeconds(endTime);
      const start = Date.parse(startDateTime);
      const end = Date.parse(endDateTime);
      const durationInMs = end - start;
      const duration = durationInMs / 60000;
      if (duration > 0) {
        this.formData.duration = duration;
      }
    },

    getTimeWithSeconds(time) {
      if (!time) {
        return "00:00:00";
      }
      return time.length === 8 ? time : time + ":00";
    },
    persistActivity() {
      const startTime =
        this.formData.date +
        "T" +
        this.getTimeWithSeconds(this.formData.startTime);
      const endTime =
        this.formData.endTime === "" || !this.formData.endTime
          ? null
          : this.formData.date +
            "T" +
            this.getTimeWithSeconds(this.formData.endTime);

      const activity = {
        id:
          this.formData.id === "" || !this.formData.id
            ? uuidv4()
            : this.formData.id,
        userId: JSON.parse(window.sessionStorage.AZUSER).id,
        title: this.formData.title,
        description: this.formData.description,
        startTime: startTime,
        endTime: endTime,
        title: this.formData.issue.title || this.formData.issue,
        source: this.formData.issue.source || "",
        externalId: this.formData.issue.externalId || 0,
      };

      AZ.createOrUpdateActivity(activity)
        .then((response) => {
          this.$emit("update", response.data);
          this.actionResult = {
            error: false,
            message:
              "Zeit am " +
              new Date().toLocaleString(undefined, {
                month: "2-digit",
                day: "2-digit",
                year: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
              }) +
              " gespeichert.",
          };
          this.makeToast();
        })
        .catch((error) => {
          this.actionResult = {
            error: true,
            message:
              "Es gab einen Fehler bei der Bearbeitung: " + error.message,
          };
          this.makeToast();
        });
    },
  },
};
</script>

<style scoped>
button {
  margin: 2rem 0 2rem 2rem;
  float: right;
}

.card-header {
  font-size: 1.2rem;
  background-color: var(--primary);
  color: white;
}

.card-body {
  padding: 0;
}

.form-container {
  padding: 2rem;
}

@media (min-width: 768px) {
  .layout-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .layout-container > div {
    padding: 0 1rem;
  }
}
@media (max-width: 768px) {
  button {
    width: 100%;
    margin: 0.4rem 0;
    float: none;
  }
  .form-container {
    padding: 1rem;
  }
}

.vs--searchable .vs__dropdown-toggle {
  background-color: white;
}
</style>
