<template>
  <div>
    <div class="d-flex justify-content-between">
      <h3>User Management</h3>
      <b-button
        class="mb-3"
        variant="primary"
        v-b-modal.user-form-modal
        @click="userData = { id: uuid(), role: 'user' }"
        >User erstellen</b-button
      >
    </div>
    <div>
      <BaseTable
        :items="users"
        :header="userHeader"
        sortBy="username"
        :sortDesc="false"
      >
        <template v-slot:cell(link)="data">
          <img
            src="edit-icon-dark.svg"
            alt="edit"
            class="mt-1 mr-3"
            v-b-modal.user-form-modal
            @click="userData = data.item"
          />
          <img
            src="delete-icon-dark.svg"
            alt="delete"
             class="mr-3"
            v-b-modal.confirm-modal
            @click="userToDelete = data.item.id"
          />
          <img
            src="download-icon-dark.svg"
            alt="download"
            v-b-modal.export-modal
            @click="selectedUser = data.item"
          />
        </template>
      </BaseTable>
      <b-modal
        id="user-form-modal"
        :title="userFormTitle"
        hide-footer
        centered
      >
        <UserForm :userData="userData" @update="updateTable" />
      </b-modal>
      <BaseConfirmationModal
        title="User löschen"
        @confirm="removeUser()"
        @hide="closeModal('confirm-modal')"
        centered
      />

      <b-modal
      id="export-modal"
        title="Zeitraum für Export wählen"
        hide-footer
        centered>
        <div class="info">User: {{selectedUser.username}}</div>
        <BaseDatePicker
          label="Startdatum"
                  @change="startDate = $event"
                  :value="startDate"
                />
        <BaseDatePicker
        label="Enddatum"
                  @change="endDate = $event"
                  :value="endDate"
                />
        <b-button @click="exportActivities()"
>Daten exportieren</b-button>
        </b-modal>
    </div>
  </div>
</template>

<script>
import AZ from "@/utils/AzApi";
import BaseTable from "@/components/BaseTable.vue";
import BaseDatePicker from "@/components/BaseDatePicker.vue";
import BaseConfirmationModal from "@/components/BaseConfirmationModal.vue";
import UserForm from "@/components/UserForm.vue";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "Admin",
  components: {
    BaseTable,
    BaseDatePicker,
    BaseConfirmationModal,
    UserForm
  },
  data() {
    return {
      users: [],
      userHeader: [
        { key: "id", label: "User-Id", sortable: true },
        { key: "username", label: "User-Name", sortable: true },
        { key: "role", label: "Rolle", sortable: true },
        { key: "link", label: "Bearbeiten", sortable: false }
      ],
      viewportWidth: window.innerWidth,
      text: "",
      actionResult: {
        error: false,
        message: ""
      },
      userToDelete: 0,
      userData: {},
      
      startDate: "",
      endDate: "",
      selectedUser: {}
    };
  },

  computed: {
    alertType() {
      return this.actionResult.error ? "danger" : "primary";
    },
    toastHeader() {
      return this.actionResult.error
        ? "Profil nicht gespeichert"
        : "Profil erfolgreich gespeichert";
    },
    userFormTitle() {
      return this.userData.name === undefined
        ? "User erstellen"
        : `User ${this.userData.username} bearbeiten`;
    }
  },

  mounted() {
    this.retrieveUsers();
  },
  methods: {
    uuid() {
      return uuidv4();
    },

    exportActivities() {
          AZ.getActivitiesForUser(this.selectedUser, this.startDate, this.endDate).then(() => {
            this.startDate = "",
            this.endDate = "",
            this.selectedUser = {}
            this.closeModal("export-modal");
          })
          // AZ.getActivitiesForUser(user, "2020-01-01", "2020-10-01")

    },

    closeModal(id) {
      this.$bvModal.hide(id);
    },
    removeUser() {
      AZ.deleteUser(this.userToDelete)
        .then(() => {
          const indexToDelete = this.users.findIndex(
            user => user.id === this.userToDelete
          );
          this.users.splice(indexToDelete, 1);
          this.actionResult = {
            error: false,
            message: "User erfolgreich gelöscht."
          };
          this.makeToast();
        })
        .catch(() => {
          this.actionResult = {
            error: true,
            message: "User konnte nicht gelöscht werden."
          };
          this.makeToast();
        });
      this.closeModal("confirm-modal");
    },
    retrieveUsers() {
      AZ.retrieveAllUsers().then(response => {
        this.users = response.data;
      });
    },
    updateTable() {
      this.retrieveUsers();
      this.closeModal("user-form-modal");
    }
  }
};
</script>

<style scoped>

#export-modal.modal-content{
  font-family: 'Courier New', Courier, monospace;
}
.info {
  color: var(--primary);
  font-family: 'Courier New', Courier, monospace;
}
</style>
