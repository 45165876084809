<template>
  <div id="app">
    <div>
      <BaseNavbar />
    </div>
    <div class="content-container">
      <router-view />
    </div>
  </div>
</template>

<script>
import BaseNavbar from "@/components/BaseNavbar.vue";
import AZ from "@/utils/AzApi";

export default {
  name: "App",
  components: {
    BaseNavbar,
  },
  data() {
    return {};
  },

  created() {
    window.addEventListener("storage", (event) => {
      const user = AZ.user();
      if (event.key === "AZ_REQUESTING_SHARED_CREDENTIALS" && user.isLoggedIn) {
        window.localStorage.setItem(
          "AZ_CREDENTIALS_SHARING",
          JSON.stringify(user)
        );
        window.localStorage.removeItem("AZ_CREDENTIALS_SHARING");
      }
      if (event.key === "AZ_CREDENTIALS_SHARING" && !user.isLoggedIn) {
        window.sessionStorage.setItem("AZUSER", event.newValue);
        window.dispatchEvent(new Event("AZUSERCHANGE"));
      }
      if (event.key === "AZ_CREDENTIALS_FLUSH" && user.isLoggedIn) {
        AZ.logout();
      }
    });
  },
};
</script>

<style scoped>
.content-container {
  max-width: 90vw;
  margin: 2rem auto;
}

@media (max-width: 768px) {
  .content-container {
    max-width: 94vw;
    margin: 1rem auto;
  }
}
</style>
