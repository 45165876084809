<template>
  <div class="mb-3">
    <label
      :for="label"
      :class="label === 'Startzeit' ? 'mb-1' : 'form-label'"
      >{{ label }}</label
    >
    <img
      v-if="label === 'Startzeit'"
      src="refresh-icon-dark.svg"
      alt="refresh"
      class="ml-2"
      @click="$emit('refresh')"
    />

    <b-input-group>
      <b-form-input
        :id="label"
        v-model="time"
        type="text"
        placeholder="HH:mm"
        :state="timeState"
        @input="$emit('change', time)"
      ></b-form-input>
      <b-input-group-append>
        <b-form-timepicker
          v-model="time"
          button-only
          right
          :aria-controls="label"
          locale="de"
          v-bind="labels.de || {}"
          hide-header
          noCloseButton
          minutes-step="15"
          @input="$emit('change', time)"
        ></b-form-timepicker>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
export default {
  name: "BaseTimePicker",
  components: {},
  props: {
    label: { type: String, default: "Zeit" },
    value: { type: String, default: "" },
  },
  data() {
    return {
      labels: {
        de: {
          labelHours: "Stunden",
          labelMinutes: "Minuten",
          labelSeconds: "Sekunden",
          labelIncrement: "Erhöhen",
          labelDecrement: "Verringern",
          labelSelected: "Ausgewählte Zeit",
          labelNoTimeSelected: "Keine Zeit ausgewählt",
        },
      },
      time: this.value,
    };
  },

  computed: {
    timeState() {
      return (
        this.time.match(
          "^([0-1][0-9]|[2][0-3]):([0-5][0-9]):?([0-5][0-9])?$"
        ) !== null || this.time === ""
      );
    },
  },

  watch: {
    value: function(newVal) {
      this.time = newVal;
    },
  },
};
</script>

<style scoped>
img {
  cursor: pointer;
}
</style>
