<template>
  <div class="home">
    <TimeTrackerForm
      :input="formData"
      @reset="resetFormData"
      @refresh="resetFormTime"
      @update="getActivities"
    />
    <div class="table-container">
      <h3>{{ $t("activities.list") }}</h3>
      <ActivitiesTable
        :entries="items"
        @edit="setFormData($event)"
        @restart="restartItem"
        @stop="stopItem"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TimeTrackerForm from "@/components/TimeTrackerForm.vue";
import ActivitiesTable from "@/components/ActivitiesTable.vue";
import { v4 as uuidv4 } from "uuid";
import AZ from "@/utils/AzApi";

export default {
  name: "Home",
  components: {
    TimeTrackerForm,
    ActivitiesTable,
  },
  data() {
    return {
      items: [],
      formData: {},
      activityToDelete: 0,
    };
  },
  computed: {},
  mounted() {
    this.getActivities();
  },

  methods: {
    closeModal() {
      this.$bvModal.hide("confirm-modal");
    },

    calculateMinutes(time) {
      const timeStr = time || new Date().toTimeString().slice(0, 5);
      const timeArr = timeStr.split(":");
      return parseFloat(timeArr[0]) * 60 + parseFloat(timeArr[1]);
    },

    calculateDuration(start, end) {
      const startInMinutes = this.calculateMinutes(start);
      const endInMinutes = this.calculateMinutes(end);
      const durationInMinutes = endInMinutes - startInMinutes;
      const minutes = durationInMinutes % 60;
      const hours = (durationInMinutes - minutes) / 60;
      const minutesFormatted = minutes < 10 ? `0${minutes}` : `${minutes}`;
      const hoursFormatted = hours < 10 ? `0${hours}` : `${hours}`;
      return `${hoursFormatted}:${minutesFormatted}`;
    },
    getActivities() {
      AZ.getActivities().then((response) => {
        this.items = response.data;
        this.items.forEach((item) => {
          item.date = item.startTime.slice(0, 10);
          item.start = item.startTime.slice(11, 16);
          item.end = item.endTime ? item.endTime.slice(11, 16) : null;
          item.duration = this.calculateDuration(item.start, item.end);
          item._showDetails = true;
        });
        this.resetFormData();
      });
    },
    deleteActivity() {
      AZ.deleteActivity(this.activityToDelete)
        .then(() => {
          const indexToDelete = this.items.findIndex(
            (item) => item.id === this.activityToDelete
          );
          this.items.splice(indexToDelete, 1);
          this.closeModal();
        })
        .catch((error) => {
          this.actionResult = {
            error: true,
            message:
              "Es gab einen Fehler bei der Bearbeitung: " + error.message,
          };
          this.makeToast();
        });
    },
    addfifteenMinutes(time) {
      const timeArr = time.split(":");
      const timeInMinutes =
        parseFloat(timeArr[0]) * 60 + parseFloat(timeArr[1]) + 15;
      const minutes = timeInMinutes % 60;
      const hours = (timeInMinutes - minutes) / 60;
      const minutesFormatted = minutes < 10 ? `0${minutes}` : `${minutes}`;
      const hoursFormatted = hours < 10 ? `0${hours}` : `${hours}`;
      return `${hoursFormatted}:${minutesFormatted}`;
    },

    resetFormTime() {
      const now = new Date();
      this.formData.startTime = now.toTimeString().slice(0, 5);
      this.formData.date = now.toISOString().slice(0, 10);
      this.formData.endTime = "";
    },

    restartItem(item) {
      const now = new Date();
      const date = item.startTime.slice(0, 11);

      const activity = {
        id: uuidv4(),
        userId: JSON.parse(window.sessionStorage.AZUSER).id,
        title: item.title,
        description: item.description,
        startTime: date + now.toTimeString().slice(0, 5),
        endTime: null,
        source: item.source || "",
        externalId: item.externalId || 0,
      };

      AZ.createOrUpdateActivity(activity).then((response) => {
        this.getActivities();
      });
    },

    stopItem(item) {
      const now = new Date();
      const date = item.startTime.slice(0, 11);
      const activity = {
        id: item.id,
        userId: JSON.parse(window.sessionStorage.AZUSER).id,
        title: item.title,
        description: item.description,
        startTime: item.startTime,
        endTime: date + now.toTimeString().slice(0, 5),
        source: item.source || "",
        externalId: item.externalId || 0,
      };

      AZ.createOrUpdateActivity(activity).then((response) => {
        this.getActivities();
      });
    },

    resetFormData() {
      const now = new Date();
      this.formData = {
        id: "",
        issue: {
          title: "",
          source: "",
          externalId: "",
        },
        description: "",
        startTime: now.toTimeString().slice(0, 5),
        date: now.toISOString().slice(0, 10),
        endTime: "",
      };
    },
    setFormData(item) {
      this.formData = {
        id: item.id,
        issue: {
          title: item.title,
          source: item.source,
          externalId: item.externalId,
        },
        description: item.description,
        startTime: item.start,
        endTime: item.end,
        date: item.date,
        duration: item.durationInMinutes,
      };
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style scoped>
h3 {
  font-size: 1.2rem;
  margin: 1rem 0;
  font-weight: bold;
}

.table-container {
  padding: 1.2rem;
}

@media (max-width: 768px) {
  .table-container {
    padding: 0;
  }
  h3 {
    text-align: center;
    margin: 2rem 0;
  }
}
</style>
