<template>
  <div class="sources-form">
    <div class="form-container">
      <b-form-group label="Quelle">
        <v-select :options="['linear', 'zoho', 'plain']" v-model="formData.name" @input="setDefaultConfig"></v-select>
      </b-form-group>
       <b-form-group label="Beschreibung">
        <b-form-input
          v-model="formData.description"
        ></b-form-input>
        </b-form-group>

        <b-form-group label="Api Key">
        <b-form-textarea
          v-model="formData.config"
        ></b-form-textarea>
        </b-form-group>
        <b-button @click="persistSource(formData)"
          >Key hinzufügen</b-button
        >
      <div v-for="source in sources" v-bind:key="source.id" class="key-container">
        {{source.name}} 
        <span v-if="source.description !== ''"> ({{source.description}})</span>
        <b-card class="key-card">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="key-info">{{source.config}}</div>
            <img @click="deleteSource(source.id)" src="delete-icon-dark.svg" alt="" class="ml-3">
          </div>
        </b-card>
        
      </div>
    </div>
  </div>
</template>

<script>
import AZ from "@/utils/AzApi";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "SourcesForm",
  components: {},
  data() {
    return {
      actionResult: {
        error: false,
        message: ""
      },
      linearFormData: {},
      zohoFormData: {},
      formData: {
       
      },
      sources: []

    };
  },

  computed: {
    alertType() {
      return this.actionResult.error ? "danger" : "primary";
    },
    toastHeader() {
      return this.actionResult.error
        ? "Api Key nicht gespeichert"
        : "Api Key erfolgreich gespeichert";
    }
  },

  mounted() {
    this.retrieveSources();
  },

  methods: {
   
    setDefaultFormData() {
      this.formData = {
        name: "",
        description: "",
        userId: AZ.user().id,
        id: uuidv4(),
        config: '{ token: "exampletoken" }'
      };
    },

    setDefaultConfig() {
      if (this.formData.name === "plain") {
        this.formData.config = '{"project": ["p1", "p2"]}'
        return;
      }
      
      this.formData.config = this.formData.name === "zoho" ? 
        '{"orgId":"123", "clientId": "foo", "clientSecret": "bar", "refreshToken": "abc"}' :
        '{ token: "exampletoken" }'
    },
    makeToast() {
      this.$bvToast.toast(this.actionResult.message, {
        title: this.toastHeader,
        variant: this.alertType,
        solid: true
      });
    },

    retrieveSources() {
      AZ.getSources().then(response => {
        this.sources = response.data;
        this.setDefaultFormData();
      });
    },

    deleteSource(id) {
      AZ.deleteSource(id)
        .then(() => {
          this.retrieveSources();
          this.actionResult = {
            error: false,
            message:
              "Api Key  am " +
              new Date().toLocaleString(undefined, {
                month: "2-digit",
                day: "2-digit",
                year: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false
              }) +
              " gelöscht."
          };
          this.makeToast();
        })
        .catch(err => {
          this.actionResult = {
            error: true,
            message: "Api Key konnte nicht gelöscht werden" + err.message
          };
          this.makeToast();
        });
    },

    persistSource(formData) {
      if (
        ![
          '{"orgId":"123", "clientId": "foo", "clientSecret": "bar", "refreshToken": "abc"}',
          '{ token: "exampletoken" }'
        ].includes(formData.config)
      ) {
        AZ.persistSource(formData)
          .then(() => {
            this.retrieveSources();
            this.actionResult = {
              error: false,
              message:
                "Api Key  am " +
                new Date().toLocaleString(undefined, {
                  month: "2-digit",
                  day: "2-digit",
                  year: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour12: false
                }) +
                " gespeichert."
            };
            this.makeToast();
          })
          .catch(err => {
            this.actionResult = {
              error: true,
              message: "Api Key konnte nicht gespeichert werden" + err.message
            };
            this.makeToast();
          });
      } else {
        this.actionResult = {
          error: true,
          message: "Api Key konnte nicht gespeichert werden: Wert bitte ändern."
        };
        this.makeToast();
      }
    }
  }
};
</script>

<style scoped>
button {
  margin: 1.4rem 0;
}

.card-body {
  padding: 0;
}

.form-container {
  padding: 1.4rem;
}

.card-header {
  font-size: 1.2rem;
  background-color: var(--primary);
  color: white;
}

.card {
  margin: 2rem 0;
}

.key-container {
  margin: 1rem 0;
}
.key-card {
  margin: 0;
  padding: 1rem;
}

.key-info {
  max-width: 80%
}



</style>
