import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { BootstrapVue } from "bootstrap-vue";
import { IconsPlugin } from "bootstrap-vue";
import vSelect from "vue-select";
import VueI18n from "vue-i18n";
import messages from "./lang";

Vue.component("v-select", vSelect);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueI18n);

Vue.config.productionTip = false;

const i18n = new VueI18n({
  locale: "de",
  messages,
});

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

import "./custom.scss";
