import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Account from "../views/Account.vue";
import Admin from "../views/Admin.vue";

import AZ from "@/utils/AzApi";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/account",
    name: "account",
    component: Account
  },
  {
    path: "/logout",
    beforeEnter: (to, from, next) => {
      AZ.logout();
      next("/login");
    }
  },
  {
    path: "/admin",
    beforeEnter: (to, from, next) => {
      if (!(AZ.user().role === "admin")) next({ name: "home" });
      else next();
    },
    name: "admin",
    component: Admin
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  if (to.name !== "login" && !AZ.user().isLoggedIn) next({ name: "login" });
  else next();
});

export default router;
